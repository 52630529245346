<template>
  <div class="all-amenities">
    <AdminTable title="All Amenities" :list="amenityList" :fields="fields" :loading="loading"
    @update-list="getAmenityList" @edit="editAmenity" @delete="deleteAmenity"/>
  </div>
</template>

<script>
import AdminTable from '@/components/admin/AdminTable'

import {amenityService} from '@/services/admin'
import { mapState } from 'vuex'

export default {
  name: 'AdminAllAmenities',
  components: {
    AdminTable,
  },
  data() {
    return {
      amenityList: [],
      loading: false,
    }
  },
  computed: {
    ...mapState({
      amenities: state => state.adminAmenityStore.amenities,
    }),
    fields() {
      return [
        { key: 'id', label: 'ID' },
        { key: 'name', label: 'Name' },
        { key: 'defaultValue', label: 'Default Value' },
        { key: 'translations.en', label: 'English', flag: 'us' },
        { key: 'translations.it', label: 'Italiano', flag: 'it' },
        { key: 'translations.es', label: 'Español', flag: 'es' },
        { key: 'actions', label: 'Actions', type: 'actions', actions: ['edit', 'delete'] },
      ]
    }
  },
  beforeMount() {
    this.amenityList = this.getAmenityList()
  },
  methods: {
    async getAmenityList() {
      this.loading = true
      this.amenityList = await this.$store.dispatch('adminAmenityStore/getAmenities')
      await this.$store.commit('adminAmenityStore/setAmenities', this.amenityList)
      this.loading = false
    },
    editAmenity(amenity) {
      this.$store.commit('adminAmenityStore/setAmenity', amenity)
      this.$router.push({ name: 'amenity-edit', query: { id: amenity.id } })
    },
    async deleteAmenity(amenity) {
      await amenityService.deleteAmenity(amenity.id)
      await this.getAmenityList();
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
